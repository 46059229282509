// COMPONENTS
import Dialog, { DialogProps, DialogButtonProps } from './Dialog';

export type ConfirmDialogProps = Omit<DialogProps, 'fullWidth' | 'buttons'> & {
  cancelText?: string;
  confirmText?: string;
  color?: DialogButtonProps['color'];
  message?: string | JSX.Element;
  confirmLoading?: boolean;
  onConfirm: () => void;
};

const ConfirmDialog = ({
  open,
  cancelText = 'Cancel',
  confirmText = 'Yes',
  color = 'accent',
  message,
  confirmLoading,
  onConfirm,
  onClose,
  children,
  ...restProps
}: ConfirmDialogProps) => {
  return (
    <Dialog
      open={open}
      fullWidth
      buttons={[
        {
          label: cancelText,
          color: 'secondary',
          onClick: () => onClose(),
        },
        {
          label: confirmText,
          color,
          loading: confirmLoading,
          onClick: async () => {
            if (onConfirm) {
              await onConfirm();
            }
            if (onClose) onClose();
          },
        },
      ]}
      onClose={onClose}
      {...restProps}
    >
      {message || children}
    </Dialog>
  );
};

export default ConfirmDialog;
